<template>
    <v-container fluid>
        <v-card :loading="loading" class="mt-3">
            <Page-Header
                :title="$t('customer.customers')"
                icon="mdi-account-group"
            >
                <template v-slot:buttons>
                    <v-btn
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <v-data-table
                :headers="headers"
                :items="customers"
                :search="search"
                :loading="loading"
                class="pa-5"
                style="overflow: hidden;"
                :loading-text="$t('loading')"
            >
                <template v-slot:top>
                    <!--Search bar-->
                    <v-text-field
                        dense
                        outlined
                        :loading="loading"
                        v-model="search"
                        append-icon="mdi-magnify"
                        :label="$t('search')"
                        single-line
                        hide-details
                    ></v-text-field>
                    <!--Save popup-->
                    <Customer-Dialog
                        :editedItem="editedItem"
                        :editedIndex="editedIndex"
                        :dialog="dialog"
                        :close="close"
                        :key="dialog"
                        :refreshCustomers="refreshTable"
                    />
                    <!--Delete popup-->
                    <confirm-dialog
                        :openDialog="dialogDelete"
                        :onClicked="deleteItemConfirm"
                        :onClose="closeDelete"
                        toolBarColor="red darken-2"
                    ></confirm-dialog>
                </template>
                <template v-slot:[`item.customerType`]="{ item }">
                    {{ customerTypesView[item.customerType] }}
                </template>
                <template v-slot:[`item.fileUrl`]="{ item }">
                    <v-btn icon :href="item.fileUrl" target="_blank">
                        <v-icon small>mdi-file</v-icon>
                    </v-btn>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                    <v-row class="text-end">
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(23)"
                                        class="mr-2"
                                        @click="editItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                </template>
                                <span> {{ $t("edit") }} </span>
                            </v-tooltip></v-col
                        >
                        <v-col cols="6"
                            ><v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-if="isInRole(24)"
                                        @click="deleteItem(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                        color="red"
                                    >
                                        mdi-delete-outline
                                    </v-icon>
                                </template>
                                <span> {{ $t("delete") }}</span>
                            </v-tooltip></v-col
                        >
                    </v-row>
                </template>
            </v-data-table>
        </v-card>
    </v-container>
</template>
<script>
import axios from "axios";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import CustomerDialog from "./CustomerDialog.vue";

export default {
    components: { ConfirmDialog, CustomerDialog, PageHeader },
    data() {
        return {
            deletedFile: null,
            deleteFileDialog: false,
            currFiles: [],
            files: [],
            customerFiles: null,
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            search: "",
            customerTypes: [
                {
                    key: this.$t("customerTypes.1"),
                    value: 1
                },
                {
                    key: this.$t("customerTypes.2"),
                    value: 2
                },
                {
                    key: this.$t("customerTypes.3"),
                    value: 3
                },
                {
                    key: this.$t("customerTypes.4"),
                    value: 4
                }
            ],
            customerTypesView: {
                1: this.$t("customerTypes.1"),
                2: this.$t("customerTypes.2"),
                3: this.$t("customerTypes.3"),
                4: this.$t("customerTypes.4")
            },
            headers: [
                {
                    text: this.$t("customer.fullName"),
                    value: "fullName"
                },
                {
                    text: this.$t("customer.phone"),
                    value: "phone"
                },
                {
                    text: this.$t("customer.address"),
                    value: "address"
                },
                {
                    text: this.$t("customer.customerType"),
                    value: "customerType"
                },
                {
                    text: this.$t("customer.customerGroup"),
                    value: "customerGroup"
                },
                {
                    text: this.$t("accountTree.account"),
                    value: "accountName"
                },
                { text: "", value: "actions" }
            ],
            customers: [],
            editedItem: {
                customerGuid: "",
                accountGuid: "",
                customerType: 1,
                fullName: "",
                surname: "",
                phone: "",
                email: "",
                address: "",
                city: "",
                notes: "",
                fileUrl: "",
                files: []
            },
            defaultItem: {
                customerGuid: "",
                accountGuid: "",
                customerType: 1,
                fullName: "",
                surname: "",
                phone: "",
                email: "",
                address: "",
                city: "",
                notes: "",
                fileUrl: "",
                files: []
            },
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (this.isInRole(22)) {
            this.loading = true;
            this.refreshTable();
        } else this.redirectUnauthorizedUsers();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.customers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.customers.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            var deleteItem = this.customers[this.editedIndex];
            axios
                .delete("customers/Delete?id=" + deleteItem.customerGuid)
                .then(response => {
                    if (response.data.status == "Successful") {
                        this.$toast.success(
                            this.$t("operationAccomplishedSuccessfully")
                        );
                    } else {
                        this.$toast.error(
                            this.$t("error." + response.data.message)
                        );
                    }
                    this.refreshTable();
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    this.refreshTable();
                    console.log(e);
                });

            this.closeDelete();
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.currFiles = [];
            this.files = [];
        },
        refreshTable() {
            axios
                .get("Customers/Get")
                .then(response => {
                    this.customers = response.data.data;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        remove(index) {
            this.files.splice(index, 1);
        },
        inputChanged() {
            this.files = [...this.currFiles, ...this.files];
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
            this.currFiles = [];
            this.files = [];
        },
        downloadFile(file) {
            window.open(file.path);
        }
    }
};
</script>
