var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"mt-3",attrs:{"loading":_vm.loading}},[_c('Page-Header',{attrs:{"title":_vm.$t('customer.customers'),"icon":"mdi-account-group"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary darken-1"},nativeOn:{"click":function($event){_vm.dialog = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("add"))+" ")],1)]},proxy:true}])}),_c('v-data-table',{staticClass:"pa-5",staticStyle:{"overflow":"hidden"},attrs:{"headers":_vm.headers,"items":_vm.customers,"search":_vm.search,"loading":_vm.loading,"loading-text":_vm.$t('loading')},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"dense":"","outlined":"","loading":_vm.loading,"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('Customer-Dialog',{key:_vm.dialog,attrs:{"editedItem":_vm.editedItem,"editedIndex":_vm.editedIndex,"dialog":_vm.dialog,"close":_vm.close,"refreshCustomers":_vm.refreshTable}}),_c('confirm-dialog',{attrs:{"openDialog":_vm.dialogDelete,"onClicked":_vm.deleteItemConfirm,"onClose":_vm.closeDelete,"toolBarColor":"red darken-2"}})]},proxy:true},{key:"item.customerType",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.customerTypesView[item.customerType])+" ")]}},{key:"item.fileUrl",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","href":item.fileUrl,"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-file")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',{staticClass:"text-end"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isInRole(23))?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")])])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isInRole(24))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("delete")))])])],1)],1)]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }